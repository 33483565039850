import { makeStyles } from '@material-ui/core';
import { theme } from '../../../../../../../themes/theme';

export const usePatientInfoViewStyles = makeStyles({
  chip: {
    width: 'fit-content',
    height: 24,
    borderRadius: 6,
    backgroundColor: theme.palette.primary.light,
    '& .MuiChip-label': {
      padding: theme.spacing(0.25, 0.875),
      fontWeight: 600,
      fontSize: 12,
      color: theme.palette.primary.main,
    },
  },
  readOnlyChipIcon: {
    color: theme.palette.error.main,
    marginLeft: 0,
    marginRight: 0,
    width: 24,
    height: 24,
  },
  clinicIDsAndPatientIDs: {
    marginBottom: theme.spacing(-1),
  },
  itemRowLow: {
    height: 32,
  },
  itemTextMiddle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& span': {
      color: theme.palette.grey[700],
      fontSize: 14,
      fontWeight: 700,
      textAlign: 'center',
    },
  },
  itemTextRight2: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& span': {
      color: theme.palette.grey[500],
      fontSize: 12,
      fontWeight: 600,
      textAlign: 'right',
    },
  },
  showMore: {
    width: '100%',
    height: 20,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderRadius: 5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(0.5),
  },
  showMoreIcon: {
    fontSize: 20,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  rotated: {
    transform: 'rotate(270deg)',
  },
  notRotated: {
    transform: 'rotate(90deg)',
  },
  openGap: {
    background: theme.palette.error.light,
    color: theme.palette.error.main,
    borderRadius: 6,
    fontSize: 12,
    padding: theme.spacing(0.25, 1.125),
    fontWeight: 600,
    height: 24,
    '&:hover': {
      cursor: 'pointer',
    },
    '& .MuiChip-label': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  closedGap: {
    background: theme.palette.grey[100],
    color: theme.palette.grey[500],
    borderRadius: 6,
    fontSize: 12,
    padding: theme.spacing(0.25, 1),
    fontWeight: 600,
    height: 24,
    '& .MuiChip-label': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    '&:hover': {
      cursor: 'pointer',
      background: theme.palette.grey[200],
    },
    '&:focus': {
      background: theme.palette.grey[100],
    },
  },
  warningIcon: {
    color: '#C05314',
    width: 16,
    height: 16,
    marginRight: 5.33,
    marginTop: 0,
    marginLeft: 0,
    '& .MuiChip-icon': {
      marginLeft: 0,
    },
  },
  panelRow: {
    // '& .MuiListItem-gutters': {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    // },
  },
  root: {
    position: 'relative',
  },
  infoSection: {
    margin: theme.spacing(0, 3.125),
    '& .MuiListItem-gutters': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  sectionTitle: {
    padding: theme.spacing(4, 0, 0, 0),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  sectionTitleText: {
    height: 28,
    lineHeight: '28px',
    fontSize: 20,
    fontWeight: theme.typography.fontWeightBold,
  },
  futureAppointmentContainer: {
    '& li': {
      height: 56,
      marginBottom: theme.spacing(1),
      padding: 0,
    },
  },
  itemRow: {
    height: 40,
  },
  itemRowContained: {
    height: 40,
    border: `1px solid rgba(0, 0, 0, 0.12)`,
    borderRadius: 8,
    backgroundColor: '#FFF',
    marginBottom: theme.spacing(1),

    '&:hover $openInNewTabTooltip': {
      visibility: 'visible',
    },
  },
  itemRowContainedTextLeft: {
    '& span': {
      color: theme.palette.grey[500],
      fontSize: 14,
      fontWeight: 700,
    },
  },
  openInNewTab: {
    color: theme.palette.primary.main,
  },
  notesRowContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'center',
    gap: 12,
    padding: theme.spacing(1.5, 2),
    backgroundColor: theme.palette.grey[50],
    borderRadius: 6,
  },
  noteContent: {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.grey[700],
    fontSize: 16,
    lineHeight: '24px',
    wordBreak: 'break-word',
  },
  notePcpAndCreatedAt: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  noteCreatedAt: {
    fontWeight: 600,
    color: theme.palette.grey[500],
    fontSize: 12,
    lineHeight: '20px',
  },

  newApptInClinicContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  newApptInClinicText: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: 12,
    borderBottom: `2px solid rgba(12, 119, 216, 0.3)`,
  },
  itemTextRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& span': {
      color: theme.palette.grey[700],
      fontSize: 14,
      fontWeight: 700,
      textAlign: 'right',
    },
  },
  itemTextLeft: {
    '& span': {
      color: theme.palette.grey[500],
      fontSize: 12,
      fontWeight: 700,
      textAlign: 'left',
    },
  },
  rowNonText: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#FFF',
    padding: theme.spacing(0, 2),
  },
  futureAppointmentSubscript: {
    fontWeight: 600,
    fontSize: 12,
    color: theme.palette.grey[500],
    marginBottom: theme.spacing(1),
  },
  familyMembersItemText: {
    '& span': {
      width: 'fit-content',
      color: theme.palette.primary.main,
      fontSize: 12,
      fontWeight: 600,
      borderBottom: 'rgba(12, 119, 216, 0.3) solid 2px',
    },
  },
  callIcon: {
    width: 16,
    height: 16,
    color: theme.palette.primary.main,
  },
  itemRowContainedNoBorder: {
    border: `none`,
    borderRadius: 8,
    backgroundColor: '#FFF',
    marginBottom: theme.spacing(1),
  },
  calendarIcon: {
    color: theme.palette.primary.main,
  },
  bookAppointmentButton: {
    backgroundColor: theme.palette.primary.light,
  },
  bookAppointmentButtonLabel: {
    color: theme.palette.primary.main,
    fontSize: 16,
    fontWeight: 700,
    lineHeight: '24px',
  },
  appointmentLocationText: {
    color: theme.palette.grey[600],
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '24px',
    paddingLeft: theme.spacing(2),
  },
  flexCenterCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  insuranceTag: {
    width: 'fit-content',
    height: '100%',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(0.25, 0.875),
    borderRadius: 6,
    fontSize: 12,
    marginRight: theme.spacing(0.5),
  },
  itemTextRightExceptPanels: {
    color: theme.palette.grey[700],
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '24px',
  },
  flexCenterEnd5: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: 5,
  },
  openInNewTabTooltip: {
    visibility: 'hidden',
    width: 'fit-content',
    height: '100%',
    color: '#FFF',
    backgroundColor: theme.palette.grey[700],
    padding: theme.spacing(0.125, 1.375, 0.375, 1.5),
    borderRadius: 6,
    fontSize: 12,
  },
  appointmentDateTime: {
    width: 138,
  },
  appointmentDate: {
    color: theme.palette.grey[500],
    fontWeight: 600,
    fontSize: 14,
  },
  appointmentTime: {
    color: theme.palette.grey[700],
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 14,
  },
  apptAndPcpContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 12,

    '& p[class*="MuiTypography-root"]': {
      whiteSpace: 'normal',
      maxWidth: 168,
    },
  },
  futureAppointmentSubtitle: {
    fontWeight: 700,
    fontSize: 14,
    color: theme.palette.grey[700],
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
  },
  deListButton: {
    color: `${theme.palette.primary.dark}`,
    border: `2px solid ${theme.palette.primary.dark}`,
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '24px',
    padding: theme.spacing(0.5, 1.25),
    width: 'fit-content',
    height: 32,

    '&:hover': {
      backgroundColor: 'rgba(67, 67, 67, 0.1)',
    },
  },
  dialogContentMain: {
    color: '#656565',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '24px' /* 150% */,
  },
  dialogContentBold: {
    fontWeight: 700,
  },
  openInNewTabSpan: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  itemRightButtonAndIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(3.5),
  },
});
