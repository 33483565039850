import React from 'react';
import {CareGapContext} from "../../../../../CareGaps/Home/tabs/Population/context";
import { AllPatientHeaderContainer } from '../../../../PanelsHome/components/AllPatient/components/AllPatientHeader';
import { AllPatientFilters } from '../../../../PanelsHome/components/AllPatient/components/AllPatientFilters';
import { usePanelPatientsTableViewStyles } from '../AssignedPatientsTable/Styles';
import {
  GetCareGapFilterOptionsQuery,
  useGetCareGapFilterOptionsQuery,
} from '../../../../../CareGaps/Home/tabs/Population/components/CareGapCreateFilter/index.generated';
import { SubMainRegion } from '../../../../../../components/SubMainRegion';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import {
  ageOptions,
  careGapStatusOptions,
  engagementStatusOptions,
  futureAppointmentOptions,
  genderOptions, MeasureYearOptions, PriorityOptions,
} from '../../../../../../enum/Panel/assignedPatientFilterOptions';
import { sortArrayObjectPipe } from '../../../../../../sharePipe/sortArrayObjectPipe';
import { UnassignedPatientsTable } from '../UnassignedPatientsTable';
import { useLanguageFilter } from '../../../../../../hooks/useLanguageFilter';
import { useMoveUnassignedPatients } from '../../../../../../hooks/useMoveUnassignedPatients';
import { LoadingSpinner } from '../../../../../../components/LoadingSpinner';
import { useLocation, useNavigate } from 'react-router-dom';
import { GroupByParent } from '../../../../../../components/CustomSelectWithLayer/GroupByParent';
import { PanelSummaryContext } from '../../../PanelSummary/components/PanelSummaryContext/PanelSummaryContext';
import { PatientFilterComponentIDs } from '../../../../../../enum/store';
import { usePatientFilterStore } from '../../../../../../store/features';

interface UnassignedPatientsTabContainerProps {
  limit?: number;
  skip?: number;
  setLimit: (limit: number) => any;
  setSkip: (limit: number) => any;
  panelId: string;
  title: string;
  tabIndex: number;
  availableActions: string[];
  selected: any[];
  setSelected: any;
  actionsFooter: any[];
  setActionsFooter: any;
  allSelected: any;
  setAllSelected: any;
  excluded: any;
  setExcluded: any;
  hardFresh: any;
  unassignedPatientCount?: number;
  setShowAssignCoordinatorDialog?: (input: boolean) => void;
  setShowManageCoordinatorDialog?: (input: boolean) => void;
  careTeamUsers?: any;
}

export const UnassignedPatientsTabContainer = (
  props: UnassignedPatientsTabContainerProps
) => {
  const classes = usePanelPatientsTableViewStyles();

  const [openFilter, setOpenFilter] = React.useState(false);
  const [enableTable, setEnableTable] = React.useState(false);
  const [isSearching, setIsSearching] = React.useState(false);
  const { setPatientFilterComponentId } = React.useContext(
      CareGapContext
  );

  const [applyFilterBtnIsClicked, setApplyFilterBtnIsClicked] = React.useState(
    false
  );
  const [loading, setLoading] = React.useState(true);
  const [displayedCount, setDisplayedCount] = React.useState(0);
  // option care gap options
  const openCareGapOptionsQuery = useGetCareGapFilterOptionsQuery();

  const getOpenCareGapOptions = (
    query: GetCareGapFilterOptionsQuery | undefined
  ) => {
    return query?.getCareGapFilterOptions;
  };

  const openCareGapOptions = React.useMemo(
    () => getOpenCareGapOptions(openCareGapOptionsQuery?.data),
    [openCareGapOptionsQuery.data]
  );

  const { languages } = useLanguageFilter(openCareGapOptions);

  const assignedPatientsFilterOptions = {
    // using hard coding options for Gender, Age, EngagementStatus and FutureAppointment
    Gender: genderOptions,
    Age: ageOptions,
    EngagementStatus: engagementStatusOptions,
    FutureAppointment: futureAppointmentOptions,
    CareGap: openCareGapOptions?.CareGap || [],
    Division:
      sortArrayObjectPipe(openCareGapOptions?.Divisions || [], 'asc', 'name') ||
      [],
    Clinics:
      sortArrayObjectPipe(openCareGapOptions?.Clinics || [], 'asc', 'name') ||
      [],
    Languages: languages || [],
    Payers: openCareGapOptions?.Payers || [],
    LineOfBusiness: GroupByParent(
      sortArrayObjectPipe(
        openCareGapOptions?.LineOfBusiness || [],
        'asc',
        'name'
      ) || []
    ),
    CareGapStatus: careGapStatusOptions,
    CallAttemptCount: openCareGapOptions?.CallAttemptCount || [],
    MeasureYearOptions: MeasureYearOptions,
    PriorityOptions: PriorityOptions,
  };

  const [patientTableQuery, setPatientTableQuery] = React.useState<any>();
  const [searchTextAndFilters, setSearchTextAndFilters] = React.useState<
    | {
        andName: string;
        andDob: string;
        // gender: string[];
        // age: string[];
        // ldosFrom: string;
        // ldosTo: string;
        // openGaps: string[];
        // hasEngagement: string;
        // hasFutureAppointment: string;
        // npis: string[];
        // dueDateFrom: string;
        // dueDateTo: string;
        // coordinationStatus: any[];
        // divisionId: any[];
        // clinicId: any[];
        // payerIds: any[];
        // languages: string[];
        // callAttemptCount: any[];
      }
    | undefined
  >(undefined);

  React.useEffect(() => {
    setPatientFilterComponentId(PatientFilterComponentIDs.UNASSIGNED_PATIENTS);
  }, [])
  React.useEffect(() => {
    if (props.skip !== undefined) {
      props.setSkip(0);
    }
  }, [enableTable, props.limit, searchTextAndFilters]);

  const [resultRows, setResultRows] = React.useState([]);
  const [sortBy, setSortBy] = React.useState({
    field: 'fullName',
    method: 'asc' as const,
  });

  const [
    enableChangeCoordinatorDialog,
    setEnableChangeCoordinatorDialog,
  ] = React.useState(false);

  const patientFilterStore = usePatientFilterStore(
    PatientFilterComponentIDs.UNASSIGNED_PATIENTS
  );
  const tmpFilters = patientFilterStore.formattedFiltersForQuery;

  const { polling, assignCoordinator, setPolling } = useMoveUnassignedPatients(
    props.panelId,
    props.allSelected,
    props.excluded,
    props.actionsFooter,
    // props.hardFresh,
    setEnableChangeCoordinatorDialog,
    setLoading,
    { ...searchTextAndFilters, ...tmpFilters }
  );
  const { setNeedToRefetch } = React.useContext(PanelSummaryContext);

  React.useEffect(() => {
    if (polling === false) {
      // props.hardFresh();
      // background refetch the panel data as patients have been moved to assigned patient list
      setNeedToRefetch(true);
      props.setSelected([]);
      setPolling(undefined);
    }
    if (polling === true) {
      setOpenFilter(false);
      props.setAllSelected(false);
    }

    props.setActionsFooter([]);
  }, [polling]);

  const navigate = useNavigate();
  const { state, pathname } = useLocation();
  React.useEffect(() => {
    if (state && state.origin) {
      if (state.origin === 'CREATE_PANEL_ASSIGN_COORDINATOR') {
        setOpenFilter(true);
      }
    }
    // NPH-2287 open manage care coordinator popup
    // from the create panel - assign coordinator button
    if (state && state.openManageCoordinatorPopup) {
      if (loading === false) {
        if (props.setShowManageCoordinatorDialog) {
          props.setShowManageCoordinatorDialog(true);
          // reset the popup status
          navigate(pathname, {
            state: {
              origin: 'CREATE_PANEL_ASSIGN_COORDINATOR',
              openManageCoordinatorPopup: false,
            },
          });
        }
      }
    }
  }, [state, props.setShowManageCoordinatorDialog, loading]);

  return (
    <SubMainRegion
      footer={props.actionsFooter.length > 0}
      header={
        props.unassignedPatientCount === 0 ? null : (
          <>
            <div className={classes.lightBackground}>
              <AllPatientHeaderContainer
                openFilter={openFilter}
                setOpenFilter={setOpenFilter}
                setIsSearching={setIsSearching}
                setEnableTable={setEnableTable}
                isSearching={isSearching}
                setSearchTextAndFilters={setSearchTextAndFilters}
              />

              <div
                className={
                  openFilter ? classes.openedDrawer : classes.closedDrawer
                }
              >
                <AllPatientFilters
                  defaultSortBy={{
                    field: 'fullName',
                    method: 'asc' as const,
                  }}
                  setSortBy={setSortBy}
                  setEnableTable={setEnableTable}
                  setIsSearching={setIsSearching}
                  applyFilterBtnIsClicked={applyFilterBtnIsClicked}
                  setApplyFilterBtnIsClicked={setApplyFilterBtnIsClicked}
                  loading={loading}
                  displayedCount={displayedCount}
                  options={assignedPatientsFilterOptions}
                  hideCoordinationFilter={true}
                  componentId={PatientFilterComponentIDs.UNASSIGNED_PATIENTS}
                />
              </div>
            </div>
            <Paper
              className={`${classes.control} ${classes.tableTitleContainer}`}
            >
              <Typography variant="h4" className={classes.tableTitle}>
                {props.title}
              </Typography>
            </Paper>
          </>
        )
      }
      headerChange={openFilter}
    >
      {polling ? (
        <LoadingSpinner />
      ) : (
        <UnassignedPatientsTable
          isSearching={isSearching}
          sortBy={sortBy}
          setSortBy={setSortBy}
          setResultRows={setResultRows}
          limit={props.limit}
          skip={props.skip}
          panelId={props.panelId}
          setLimit={props.setLimit}
          setSkip={props.setSkip}
          title={props.title}
          tabIndex={props.tabIndex}
          availableActions={props.availableActions}
          selected={props.selected}
          setSelected={props.setSelected}
          actionsFooter={props.actionsFooter}
          setActionsFooter={props.setActionsFooter}
          allSelected={props.allSelected}
          setAllSelected={props.setAllSelected}
          excluded={props.excluded}
          setExcluded={props.setExcluded}
          hardFresh={props.hardFresh}
          setShowAssignCoordinatorDialog={props.setShowAssignCoordinatorDialog}
          setShowManageCoordinatorDialog={props.setShowManageCoordinatorDialog}
          enableTable={enableTable}
          setLoading={setLoading}
          setDisplayedCount={setDisplayedCount}
          setSearchTextAndFilters={setSearchTextAndFilters}
          setPatientTableQuery={setPatientTableQuery}
          assignCoordinator={assignCoordinator}
          enableChangeCoordinatorDialog={enableChangeCoordinatorDialog}
          setEnableChangeCoordinatorDialog={setEnableChangeCoordinatorDialog}
        />
      )}
    </SubMainRegion>
  );
};
