import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

export const usePanelPatientsTableViewStyles = makeStyles((theme: Theme) =>
  createStyles({
    hintIcon: {
      width: 60,
      height: 60,
      color: theme.palette.primary.main,
      marginBottom: theme.spacing(2.125),
    },
    sentChip: {
      background: theme.palette.grey['100'],
      color: theme.palette.grey['500'],
      borderRadius: 6,
      fontSize: 12,
      padding: theme.spacing(0.25, 1.125),
      fontWeight: 600,
      height: 24,

      '& .MuiChip-label': {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    sendingIcon: {
      color: '#0C77D8',
      width: 16,
      height: 16,
      marginRight: 5.33,
      marginTop: 0,
      marginLeft: 0,
      '& .MuiChip-icon': {
        marginLeft: 0,
      },
    },
    sendingChip: {
      background: theme.palette.primary.light,
      color: theme.palette.primary.main,
      borderRadius: 6,
      fontSize: 12,
      padding: theme.spacing(0.25, 1.125),
      fontWeight: 600,
      height: 24,

      '& .MuiChip-label': {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    failChip: {
      background: '#FCF3E5!important',
      color: '#C05314',
      borderRadius: 6,
      fontSize: 12,
      padding: theme.spacing(0.25, 1.125),
      fontWeight: 600,
      height: 24,

      '& .MuiChip-label': {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    failAllChip: {
      background: '#FFEAEB',
      color: '#B7373E',
      borderRadius: 6,
      fontSize: 12,
      padding: theme.spacing(0.25, 1.125),
      fontWeight: 600,
      height: 24,

      '& .MuiChip-label': {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    warningIcon: {
      color: '#C05314',
      width: 16,
      height: 16,
      marginRight: 5.33,
      marginTop: 0,
      marginLeft: 0,
      '& .MuiChip-icon': {
        marginLeft: 0,
      },
    },
    failIcon: {
      color: '#B7373E',
      width: 16,
      height: 16,
      marginRight: 5.33,
      marginTop: 0,
      marginLeft: 0,
      '& .MuiChip-icon': {
        marginLeft: 0,
      },
    },
    root: {},
    closedGap: {
      background: theme.palette.grey[100],
      color: theme.palette.grey[500],
      borderRadius: 6,
      fontSize: 12,
      padding: theme.spacing(0.25, 1),
      fontWeight: 600,
      height: 24,
      '& .MuiChip-label': {
        paddingLeft: 0,
        paddingRight: 0,
      },
      '&:hover': { background: theme.palette.grey[200] },
      '&:focus': {
        background: theme.palette.grey[100],
      },
    },
    control: {
      padding: theme.spacing(2),
    },

    li: {
      margin: theme.spacing(0),
      padding: theme.spacing(0),
      listStyle: 'none',
    },

    tableTitleContainer: {
      position: 'relative',
      backgroundColor: theme.palette.grey['50'],
      width: '100%',
      height: '56px',
      borderRadius: '0px',
      padding: theme.spacing(0),
      margin: theme.spacing(0),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
    tableTitle: {
      fontSize: '20px',
      fontWeight: theme.typography.fontWeightBold,
      // lineHeight: '28px',
      textAlign: 'left',
      // height: '24px',
      color: theme.palette.text.primary,
      marginLeft: theme.spacing(3),
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5),
    },
    tableTitleRowButtons: {
      marginRight: theme.spacing(3),
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: 12,
      '& div:first-child': {
        marginRight: 0,
      },
      '& button#export-option-button': {
        backgroundColor: theme.palette.grey[200],
      },
      '& button#export-option-button:hover': {
        backgroundColor: theme.palette.grey[100],
      },
      '& button#export-option-button svg': {
        color: theme.palette.grey[500],
      },
      '& button#export-option-button span': {
        color: theme.palette.grey[600],
      },
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    tableHeader: {
      backgroundColor: '#ededed',
      color: theme.palette.grey[500],
      fontSize: '12px',
      fontWeight: theme.typography.fontWeightMedium,
      letterSpacing: '0px',
      lineHeight: '16px',
      width: '100%',
      height: '40px',
      borderRadius: '0px',
      boxShadow: 'none',
    },

    tableHeaderCell: {
      color: theme.palette.grey[500],
      fontSize: '12px',
      fontWeight: theme.typography.fontWeightBold,
      letterSpacing: '0px',
      lineHeight: '16px',
    },
    tableBodyRow: {
      width: '100%',
      height: '56px',
      borderRadius: '0px',
      backgroundColor: theme.palette.primary.contrastText,
    },

    tableBodyRowCell: {
      height: '56px',
      color: theme.palette.grey[600],
      fontSize: '14px',
      fontWeight: theme.typography.fontWeightMedium,
      letterSpacing: '0px',
      lineHeight: '24px',
    },

    insuranceText: {
      display: 'block',
      color: theme.palette.primary.main,
      fontSize: '12px',
      fontWeight: 600,
      letterSpacing: '0px',
      lineHeight: '20px',
      marginLeft: theme.spacing(1.125),
      paddingTop: theme.spacing(0.25),
      paddingBottom: theme.spacing(0.25),
      paddingLeft: 7,
      paddingRight: 7,
      whiteSpace: 'nowrap',
      width: 'fit-content',
      height: '20px',
      backgroundColor: theme.palette.primary.light,
      borderRadius: '6px',
      textAlign: 'center',
    },

    pcp: {
      borderBottom: '2px solid rgba(67, 67, 67, 0.3)',
    },
    openGap: {
      background: theme.palette.error.light,
      color: theme.palette.error.main,
      borderRadius: 6,
      fontSize: 12,
      padding: theme.spacing(0.25, 1.125),
      fontWeight: 600,
      height: 24,

      '& .MuiChip-label': {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    tableContainer: {
      overflowX: 'initial',
      border: 0,
      borderRadius: '0px',
      padding: 0,
      '& .MuiTableHead-root': {
        boxShadow:
          '0px 1px 0px 0px rgb(0 0 0 / 5%), 0px 3px 6px 0px rgb(0 0 0 / 8%)',
        background: theme.palette.grey[50],
      },

      '& > table > thead > tr > th': {
        paddingLeft: theme.spacing(3),
        padding: 0,
        height: 32,
        fontSize: 12,
        color: theme.palette.grey[500],
        fontWeight: theme.typography.fontWeightBold,
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        backgroundColor: theme.palette.grey[50],
      },

      '& > table > thead > tr': {
        height: 56,
      },

      '& > table > tbody > tr': {
        height: 56,
        // backgroundColor: theme.palette.primary.contrastText,
        cursor: 'pointer',
      },

      '& > table > tbody > tr > td.MuiTableCell-paddingCheckbox': {
        padding: theme.spacing(0, 0, 0, 3),
      },

      '& > table > tbody > tr > th': {
        padding: theme.spacing(0.75, 2),
      },

      '& > table > tbody > tr > *': {
        fontSize: 14,
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.grey[600],
        padding: theme.spacing(1.875, 3),
      },

      '& > table > tbody > tr:last-child': {
        borderBottom: '1px solid #D8D8D8',
      },
    },

    pagination: {
      display: 'flex',
      justifyContent: 'flex-end',
      '& div.MuiInputBase-root.MuiTablePagination-input.MuiTablePagination-selectRoot': {
        order: -1,
        marginRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightBold,
      },
      '& p[id]': {
        marginRight: theme.spacing(5.75),
      },
    },
    // table head
    checkbox: {
      width: 24,
      height: 24,
      padding: 0,
      marginLeft: 12,
    },
    moreHoriz: {
      color: theme.palette.grey[500],
    },
    tabIconsNormal: {
      width: 20,
      height: 20,
      display: 'inline-block',
      color: theme.palette.grey[500],
    },
    tabIconsActive: {
      width: 20,
      height: 20,
      display: 'inline-block',
      color: theme.palette.primary.main,
    },
    tabTextActive: {
      fontWeight: theme.typography.fontWeightBold,
      color: '#000',
    },
    tabTextNormal: {
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.grey[500],
    },
    closedDrawer: {
      display: 'none',
      transition: 'all 1s',
    },
    openedDrawer: {
      display: 'block',
      transition: 'all 3s',
    },
    lightBackground: {
      backgroundColor: `${theme.palette.grey[50]}`,
    },
    noContentTableRowText: {
      fontSize: 14,
      lineHeight: '24px',
      color: theme.palette.grey[400],
      fontWeight: 500,
      paddingLeft: 72,
    },
    chip: {
      width: 'fit-content',
      height: 24,
      borderRadius: 6,
      backgroundColor: theme.palette.primary.light,
      '& .MuiChip-label': {
          padding: theme.spacing(0.25, 0.875),
          fontWeight: 600,
          fontSize: 12,
          color: theme.palette.primary.main,
      },
    },
  })
);
